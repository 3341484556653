<template>
    <template v-if="component.Unterseiten.length">
        <Popover
            class="relative hidden lg:block">
            <PopoverButton class="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                <span>{{ component.Seite.data.attributes.Titel }}</span>
                <Icon
                    name="heroicons:chevron-down"
                    class="h-3 w-3"
                    aria-hidden="true" />
            </PopoverButton>

            <Transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1">
                <PopoverPanel
                    v-slot="{close}"
                    class="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4 z-50">
                    <div class="w-56 shrink rounded-xl bg-white p-4 text-sm/6 font-semibold text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                        <NuxtLink
                            v-for="child in component.Unterseiten"
                            :key="child.id"
                            :to="localePath('/' + component.Seite?.data?.attributes?.url + '/' + child.Seite?.data?.attributes?.url)"
                            class="block p-2 hover:text-primary"
                            @click="close">
                            {{ child.Seite?.data?.attributes?.Titel }}
                        </NuxtLink>
                    </div>
                </PopoverPanel>
            </Transition>
        </Popover>
        <Disclosure
            v-slot="{ open }"
            as="div"
            class="-mx-3 block lg:hidden">
            <DisclosureButton class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">
                {{ component.Seite.data.attributes.Titel }}
                <Icon
                    name="heroicons:chevron-down"
                    class="h-5 w-5 flex-none"
                    :class="[open ? 'rotate-180' : '']"
                    aria-hidden="true" />
            </DisclosureButton>
            <DisclosurePanel
                v-slot="{close}"
                class="mt-2 space-y-2">
                <NuxtLink
                    v-for="child in component.Unterseiten"
                    :key="child.id"
                    :to="localePath('/' + component.Seite.data?.attributes?.url + '/' + child.Seite.data?.attributes?.url)"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm/7 font-semibold text-gray-900 hover:bg-gray-50"
                    @click="close && emits('closeMobileMenu')">
                    {{ child.Seite?.data?.attributes?.Titel }}
                </NuxtLink>
            </DisclosurePanel>
        </Disclosure>
    </template>
    <template v-else>
        <NuxtLink
            :to="localePath('/' + url)"
            class="hidden lg:block text-sm font-semibold leading-6 text-gray-900">
            {{ Titel }}
        </NuxtLink>
        <NuxtLink
            :to="localePath('/' + url)"
            class="-mx-3 lg:hidden block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-500"
            @click="emits('closeMobileMenu')">
            {{ Titel }}
        </NuxtLink>
    </template>
</template>

<script setup>
const props = defineProps({
    component: {
        type: Object,
        required: true
    }
})

const emits = defineEmits(['closeMobileMenu'])

const Titel = computed(() => props.component.Seite.data.attributes.Titel)
const url = computed(() => props.component.Seite.data.attributes.url)
</script>
